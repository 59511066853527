import React from 'react'
import { withPrefix } from 'gatsby'

import './icon.scss'

const Icon = ({ name, size }) => (
  <svg className="icon" width={`${size}px`} height={`${size}px`}>
    <use xlinkHref={withPrefix(`icons.svg#icon-${name}`)} />
  </svg>
)

export default Icon
