import React, { useEffect, useState } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Icon from 'components/icon'
import Fade from 'components/fade'

const ScrollTop = () => {
  const [show, setShow] = useState(false)
  const handleScroll = () => {
    const showOffset = 300
    const position = window.pageYOffset
    setShow(position > showOffset)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Fade show={show}>
      <div onClick={() => scrollTo('#top')}>
        <Icon name="arrow-up" size="20" />
      </div>
    </Fade>
  )
}

export default ScrollTop
