import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import ScrollTop from 'components/scroll-top'
import Layout from 'components/layout'
import './post.scss'

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
      }
      body
    }
  }
`

export default ({ data: { mdx: post } }) => {
  const title = `${post.frontmatter.title} | by Marek Slawinski`
  return (
    <Layout>
      <Helmet title={title} />
      <h1 className="post-title">{post.frontmatter.title}</h1>
      <p className="post-date">{post.frontmatter.date}</p>
      <MDXRenderer>{post.body}</MDXRenderer>
      <div className="scroll-top-container">
        <ScrollTop />
      </div>
    </Layout>
  )
}
